import { useState } from "react";

const ContactForm = ({ existingContact = {}, updateCallback }) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/send_email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    firstName,
                    lastName,
                    email,
                    phone,
                    message,
                }),
            });
            if (response.ok) {
                alert('Message Sent! Thank you for reaching out. We will get back to you soon.');
                setFirstName("");
                setLastName("");
                setEmail("");
                setPhone("");
                setMessage("");
            } else {
                const errorData = await response.json();
                alert(`Failed to send message: ${errorData.error}`);
            }
        } catch (error) {
            console.error('Error sending message:', error);
            alert('Failed to send message. Please try again later.');
        }
    };


    return (
        <div>
            <form className="contact-form" onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="firstName">*First Name:</label>
                    <input type="text" id="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                </div>
                <div>
                    <label htmlFor="lastName">*Last Name:</label>
                    <input type="text" id="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                </div>
                <div>
                    <label htmlFor="email">*Email:</label>
                    <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </div>
                <div>
                    <label htmlFor="phone">Phone:</label>
                    <input type="text" id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="message">Message:</label>
                    <textarea id="message" value={message} onChange={(e) => setMessage(e.target.value)} rows="5" cols="50" />
                </div>
                <div className="sub-btn">
                    <button className="pvt-btn sub-btn" type="submit">Submit</button>
                </div>
            </form>
        </div>
    );
};

export default ContactForm;
