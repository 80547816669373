import React from 'react';
import './style.css';
import instagramIcon from './images/instagram.png';
import youtubeIcon from './images/youtube.png';
import linkedinIcon from './images/linked-in.png';

const BaseLayout = ({ children }) => {
  return (
    <div>
      {/* Navbar */}
      <nav>
        <a href="/">
          <h1>Danny Johns</h1>
        </a>
      </nav>

      {/* Children components */}
      <div className="main-content">
        {children}
      </div>

      {/* Social accounts - Fixed to the right */}
      <div className="socials">
        <a href="https://www.instagram.com/thedannyjohns/" target="_blank" rel="noopener noreferrer">
          <img src={instagramIcon} alt="Instagram" loading="lazy" className="socicon" />
        </a>
        <a href="https://www.youtube.com/@dannyjohns3482" target="_blank" rel="noopener noreferrer">
          <img src={youtubeIcon} alt="YouTube" loading="lazy" className="socicon" />
        </a>
        <a href="https://www.linkedin.com/in/danny-johns-6994816a/" target="_blank" rel="noopener noreferrer">
          <img src={linkedinIcon} alt="Linkedin" loading="lazy" className="socicon" />
        </a>
      </div>

      {/* Footer section */}
      <footer>
        <p className="copy">&copy; Copyright 2024</p>
        <p className="mejh">
          Built with &#x2661; by&nbsp;
          <a href="https://mejh-bojangles.github.io/" target="_blank" rel="noopener noreferrer">
            MEJH (Bojangles)
          </a>
        </p>
      </footer>
    </div>
  );
};

export default BaseLayout;
