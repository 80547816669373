import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import video from './videos/DanBioVideo.mp4';

class BioVideo extends Component {
    render() {
        return (
            <div className='player-wrapper'>
                <ReactPlayer
                    className='react-player fixed-bottom'
                    url={video}
                    width='90%'
                    height='90%'
                    controls={true}

                />
            </div>
        )
    }
}

export default BioVideo;