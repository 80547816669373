import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import BaseLayout from './BaseLayout';
import ContactForm from "./ContactForm";
import DanHeadshotImg from './images/danheadshot.jpg';
import Lessons from './Lessons';
import BioVideo from './BioVideo';
import './style.css';

const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentContact, setCurrentContact] = useState({});

  const openCreateModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentContact({});
  };

  const onUpdate = () => {
    closeModal();
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <BaseLayout>
            <div>
              <div className="home">
                <BioVideo />
                <a href="/lessons" className="pvt-btn lsn-btn">Private Lessons</a>
              </div>

              <div className='hero-quote'>
                <p className='quote'>We have the opportunity with music to take part in something bigger than ourselves. It’s something that
                  touches people in unimaginable ways and the journey of sharing in those experiences in what it’s all about.
                </p>
                <p className='tilde'>~&#9835;∽</p></div>
              <div className="hero">
                <h1>A Little Ditty About Danny</h1>
                <img src={DanHeadshotImg} alt="danny-johns" loading="lazy" />
                <p className='bio'>Danny Johns is originally from Pittsburgh, PA. After earning his BFA in Performance from Berklee College of Music, he moved to Nashville, TN to pursue a career in the music business.
                  Over the years he has cultivated a broad network of relationships with others in the industry.
                  He has played in numerous original showcases and regularly performs with different acts on Broadway.
                  Danny is a multifaceted entertainer whether he's on the stage, in the studio, or with his students.

                  He's shared stages with Eric Church and Kylie Morgan; played drums for various artists including: J. Hartley, Lana Scott, Jordan Rainer, Jay Allen, and Nick Hickman.
                  Whether you need a solid drummer, dynamic vocals, supportive mentor, or just a smile and a positive attitude, Danny is your guy.
                  <br /> <br /> For lessons, use the contact form below or visit the Private Lessons page for details.
                </p>

              </div>

              <div className="contact">
                <div className="btn">
                  <button onClick={openCreateModal} className="pvt-btn cnt-btn">Contact Danny</button>
                  {isModalOpen && (
                    <div className="contact-modal">
                      <div className="contact-modal-content">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <ContactForm existingContact={currentContact} updateCallback={onUpdate} />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="videos">
                <div className="video-container">
                  <div className="video-card">
                    <iframe width="420" height="235" src="https://www.youtube.com/embed/VSXIVGGHdGc?si=j0J_F8J_IRUS_rU6"
                      title="YouTube video player" frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen></iframe>
                  </div>

                  <div className="video-card">
                    <iframe width="420" height="235" src="https://www.youtube.com/embed/zqC3-qrMBVc?si=BTY_pB4GRt6YMoj3"
                      title="YouTube video player" frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen></iframe>
                  </div>
                </div>
              </div>
            </div>
          </BaseLayout>
        } />
        <Route path="/lessons" element={<Lessons openCreateModal={openCreateModal} isModalOpen={isModalOpen} closeModal={closeModal} currentContact={currentContact} onUpdate={onUpdate} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
