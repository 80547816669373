import { React, useState } from 'react';
import BaseLayout from './BaseLayout';
import ContactForm from "./ContactForm"
import LessonsHeadshotImg from './images/lessonsheadshot.png';


const Lessons = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentContact, setCurrentContact] = useState({});

    const openCreateModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setCurrentContact({});
    };

    const onUpdate = () => {
        closeModal();
    };

    return (
        <BaseLayout>
            {/* Lessons & Studio Policy */}

            <div className="lessons">
                <img src={LessonsHeadshotImg} alt="danny-johns" loading="lazy" />

                <div className="lessons-container-wrapper">
                    <div className="lessons-container">
                        <div className="lesson">1 Lesson <br /> 45 Minutes <br /> $60</div>
                    </div>
                    <div className="lessons-container">
                        <div className="lesson">4 Lessons <br /> 45 Minutes Each <br /> $200</div>
                    </div>
                </div>
            </div>

            <div className='studio-policy'>
                <h1>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Studio Policy</h1>
                <p>Danny’s drum studio always strives to provide a high quality enjoyable lesson environment.
                    To insure that everyone is getting the most out of their time the following policy is in place:</p>
                <h2>Attendance & Cancellations:</h2>
                <p> Danny's drum studio has a strict 24-hour cancellation policy. Students who do not give at least 24 hours advance notice will be charged the full amount for their missed lesson(s).
                    A lesson may be rescheduled at no charge if proper 24 hour notice is given, but lessons are never refundable. If you must reschedule a lesson, please email or call immediately. </p>
                <h2>Payment & Experation:</h2>
                <p>Upfront payment is required. Multiple forms of payment accepted. All lessons and lesson packages expire 6 months from the date of purchase.</p>
            </div>
            <div className="contact">
                <div className="btn lsn-btn">
                    <button onClick={openCreateModal} className="pvt-btn" type="submit">Contact Danny</button>
                    {isModalOpen && (
                        <div className="contact-modal">
                            <div className="contact-modal-content">
                                <span className="close" onClick={closeModal}>&times;</span>
                                <ContactForm existingContact={currentContact} updateCallback={onUpdate} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </BaseLayout>
    );
};

export default Lessons;
